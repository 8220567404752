<template>
  <div class="gamePrint">
    <!-- 内容 -->
    <div class="tc-report-main PageNext" v-for="(item,key) in gameData" :key="key">
      <div class="content">
        <!-- 标题 -->
        <div class="report-title">
          <p>{{item.game_title}}-报告分析</p>
        </div>
        <!-- 用户信息 -->
        <div class="userInfo">
          <table>
            <tr>
              <td>
                <span>姓名：</span>
                {{item.customer.name}}
              </td>
              <td>
                <span>性別：</span>
                {{item.customer.sex}}
              </td>
              <td>
                <span>出生日期：</span>
                {{item.customer.birthday}}
              </td>
            </tr>
            <tr>
              <td>
                <span>编号：</span>
                {{item.customer.account}}
              </td>
              <td>
                <span>部门：</span>
                {{item.customer.department}}
              </td>
              <td>
                <span>报告日期：</span>
                {{item.customer.evaluating_time}}
              </td>
            </tr>
          </table>
        </div>
        <!-- 测试结果 -->
        <p class="analyzeTitle">测试结果：</p>
        <div class="analyze">
          <div class="analyze-table">
            <table cellspacing="0">
              <tr>
                <th>因子</th>
                <th>得分</th>
                <th>用时</th>
                <th>结果</th>
              </tr>
              <tr>
                <td>{{item.results.name}}</td>
                <td>{{item.results.score}}</td>
                <td>
                  <span v-if="item.customer.staytime ==0">--</span>
                  <span v-else>{{item.customer.staytime}}秒</span>
                </td>
                <td>{{item.results.mark}}</td>
                <td></td>
              </tr>
            </table>
          </div>
          <div>
            <span>总结：</span>
            {{item.results.mark}}
          </div>
          <div>
            <span>说明：</span>
            <p v-html="item.results.comment"></p>
          </div>
          <div>
            <span>得分：</span>
            {{item.results.score}}
          </div>
        </div>
        <!-- 医生签名 -->
        <div class="signature">
          <span class="title">
            报告医生 ：
            <span class="signature_img">
              <img :src="item.admin.sign_img" alt width="150" />
            </span>
          </span>
          <span class="title data">报告日期 ：</span>
          <span>{{item.customer.evaluating_time}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { gameResult } from "@/api/game.js";
export default {
  data() {
    return {
      id: "",
      gameData: [],
    };
  },
  created() {
    // 获取游戏id
    this.id = this.$route.query.id;
    // 发生请求获取报告详情
    this.loadGameInfo();
  },
  methods: {
    loadGameInfo() {
      gameResult({ id: this.id }).then((res) => {
        if (res.code == 400200) {
          this.gameData = res.data;
          this.$nextTick(() => {
            window.print();
          });
        }
      });
    },
  },
};
</script>
<style lang="less">
@media print {
  .printbtn {
    display: none;
  }
  .PageNext {
    page-break-after: always;
  }
  .seal {
    page-break-inside: avoid;
  }
}
.gamePrint {
  margin-top: 20px;
  // 报告
  .tc-report-main {
    width: 100%;
    // 报告内容部分
    .content {
      width: 900px;
      margin: auto;
      background: rgb(255, 255, 255);
      padding: 20px 60px;
      box-sizing: border-box;
      .report-title {
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        padding: 20px 0;
      }
      //   用户信息
      .userInfo {
        margin-top: 20px;
        table {
          margin: auto;
          width: 100%;
          border-top: 1px black dashed;
          border-left: 1px black dashed;
          tr {
            width: 100%;
            // font-size: 16px;
            // font-weight: 700;
            td {
              padding: 5px;
              border-bottom: 1px black dashed;
              border-right: 1px black dashed;
              &:nth-child(1) {
                width: 220px;
              }
              &:nth-child(2) {
                width: 180px;
              }
              &:nth-child(3) {
                width: 300px;
              }
              span {
                // font-size: 14px;
                font-weight: 700;
              }
            }
          }
        }
      }
      .analyzeTitle {
        padding: 20px 0;
        font-size: 25px;
        font-weight: 700;
        color: rgb(255, 162, 23);
      }
      .analyze {
        .analyze-table {
          margin-bottom: 8px;
          table {
            width: 100%;
            tr {
              th {
                text-align: left;
                border-bottom: 1px rgb(148, 147, 147) solid;
                padding: 4px 0;
              }
              td {
                border-bottom: 1px rgb(148, 147, 147) solid;
                padding: 4px 0;
                max-width: 100px;
              }
            }
          }
        }
        >span {
          font-weight: 600;
          font-size: 16px;
        }
        div {
          padding: 4px 0;
        }
      }
      // 签名
      .signature {
        position: relative;
        text-align: right;
        margin-top: 40px;
        .title {
          font-weight: 700;
          .signature_img {
            display: inline-block;
            min-width: 100px;
          }
        }
        .data {
          margin-left: 20px;
        }
        img {
          // position: absolute;
          // top: -40px;
          // left: 284px;
        }
      }
    }
  }
}
</style>